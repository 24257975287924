import React from "react";
import Img from "gatsby-image";
import CalendarIcon from "../../static/calendarIcon";
import { Link } from "gatsby";
import TagIcon from "../../static/tagIcon";
import styles from "./BlogPostBox.module.scss";

const BlogPostBox = ({ blog }) => {
  return (
    <Link to={blog.fields.slug} className={styles.blogPost}>
      <div className={styles.imageContainer}>
        <Img fixed={blog.frontmatter.featuredImage.childImageSharp.fixed} />
      </div>

      <div className={styles.blogDetails}>
        <h2 className={styles.title}>{blog.frontmatter.title}</h2>

        <div className={styles.metadata}>
          <span className={styles.metadataSection}>
            <CalendarIcon />
            <span> {blog.frontmatter.date}</span>
          </span>

          <span className={styles.metadataSection}>
            <TagIcon />
            <span> {blog.frontmatter.mainTag}</span>
          </span>
        </div>

        <div className={styles.excerpt}>{blog.frontmatter.description}</div>
      </div>
    </Link>
  );
};

export default BlogPostBox;
