import React from "react";

function Icon() {
  return (
    <svg
      width={"16px"}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 477.863 477.863"
      version="1.1"
      viewBox="0 0 477.863 477.863"
      xmlSpace="preserve"
    >
      <path d="M460.796 0H273.063a17.066 17.066 0 00-12.066 5.001l-256 256c-6.663 6.665-6.663 17.468 0 24.132L192.73 472.866c6.665 6.662 17.468 6.662 24.132 0l256-256c3.201-3.2 5-7.54 5.001-12.066V17.067C477.863 7.641 470.222 0 460.796 0zM443.73 197.734L204.796 436.668 41.195 273.067 280.129 34.133H443.73v163.601z"></path>
      <path d="M358.396 68.267c-28.277 0-51.2 22.923-51.2 51.2s22.923 51.2 51.2 51.2 51.2-22.923 51.2-51.2-22.923-51.2-51.2-51.2zm0 68.266c-9.426 0-17.067-7.641-17.067-17.067s7.641-17.067 17.067-17.067 17.067 7.641 17.067 17.067-7.641 17.067-17.067 17.067z"></path>
    </svg>
  );
}

export default Icon;
